import React from 'react';
import firebase from 'firebase/app'
import { Button, Grid, TextField, Typography } from '@material-ui/core';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import MuiPhoneNumber from 'material-ui-phone-number';
import validator from 'validator';
export default function AccountEdit(props) {
    const [name, setName] = React.useState("");
    const [email, setEmail] = React.useState("");
    const [validEmail, setValidEmail] = React.useState(false);
    const [updateSuccess, setUpdateSuccess] = React.useState(false);
    const [phone, setPhone] = React.useState();
    const [validPhone, setValidPhone] = React.useState(true);
    const clearSuccess = () => {
        setUpdateSuccess(false);
    }
    const updateInformation = () => {
        firebase.firestore().collection("USERS").doc(props.user.uid).update({ "Info": { "name": name, "email": email, "phone": phone ? phone : "" } });
        setUpdateSuccess(true);
    }
    React.useEffect(() => {
        if (phone !== undefined) {
            setValidPhone(!validator.isMobilePhone(phone))
        }
        else {
            setValidPhone(true)
        }
    }, [phone])
    React.useEffect(() => {
        var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
        if (!pattern.test(email)) {
            setValidEmail(true);
        }
        else {
            setValidEmail(false)
        }
    }, [email])
    const setData = (Data) => {
        setName(Data.name);
        setEmail(Data.email);
        setPhone(Data?.phone);
    }
    React.useEffect(() => {
        firebase.firestore().collection("USERS").doc(props.user.uid).get().then((userDoc) => {
            var dataT;
            if (userDoc.exists) {
                var userData = userDoc.data();
                if (userData.Info === undefined) {
                    //Data doesn't exist lets save it. 
                    dataT = {
                        "name": props.user.displayName,
                        "email": props.user.email
                    };
                    firebase.firestore().collection("USERS").doc(props.user.uid).update({ "Info": dataT });
                    setData(dataT);
                }
                else {
                    setData(userData.Info);
                }

            }
            else {
                //Logged in but doesn't have account Set up
                dataT = {
                    "name": props.user.displayName,
                    "email": props.user.email
                };
                //Save user initial info
                firebase.firestore().collection("USERS").doc(props.user.uid).set({ "Info": dataT });
                setData(dataT);
            }

        })

    }, [props]);
    return <Grid container spacing={2} alignItems="center" justify="center" style={{ padding: "2%" }}>
        <Grid item lg={12} md={12} sm={12} xs={12} align="center">
            <Typography variant="h3">
                Account Information
            </Typography>
        </Grid>
        <Grid item lg={6} md={6} sm={6} xs={12} align="center">
            <TextField variant="outlined" style={{ width: "100%" }} label="Full Name" value={name} error={name.length === 0} onChange={(e) => { setName(e.target.value) }} />
        </Grid>
        <Grid item lg={6} md={6} sm={6} xs={12} align="center">
            <TextField variant="outlined" style={{ width: "100%" }} label="Email" error={validEmail} value={email} onChange={(e) => { setEmail(e.target.value) }} />
        </Grid>
        <Grid item lg={6} md={6} sm={6} xs={12} align="center">
            <MuiPhoneNumber defaultCountry={'us'}
                style={{ width: "100%" }}
                error={validPhone}
                value={phone}
                fullwidth="true"
                variant="outlined"
                disableDropdown
                countryCodeEditable={false}
                onChange={(e) => { setPhone(e) }}
                label="Phone" />
        </Grid>
        <Grid item lg={12} md={2} sm={2} xs={2} align="center">
            <Button variant="contained" onClick={updateInformation} disabled={validEmail && name.length === 0 && phone?.lenth === 0} >Update</Button>
        </Grid>


        <Snackbar open={updateSuccess} autoHideDuration={6000} onClose={clearSuccess}>
            <MuiAlert elevation={6} variant="filled" {...props}>Your Information has been updated.</MuiAlert>
        </Snackbar>

    </Grid>
}