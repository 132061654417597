import { Grid, Switch, Typography,Popover,IconButton } from '@material-ui/core';
import React from 'react';
import { Translate } from 'react-localize-redux';
import NumberFormat from 'react-number-format';
import firebase from 'firebase/app';
import HelpIcon from '@material-ui/icons/Help';
import CalculateHeat from '../Calculations/CalculateHeat';

export default function Heating(props){
    const [BTUCalculated, setBTUCalculated] = React.useState(0);
    const [efficient, setEfficient] = React.useState(false);
    const [calcPrice, setCalcPrice] = React.useState();
    const [furnaceBasePrice ,setFurnaceBasePrice] =React.useState();
    const [efficientPrice ,setEfficientPrice] =React.useState();
    const [anchorefficientHelp, setanchortEfficientHelp] = React.useState(null);
    const [efficientHelpOpen, setEfficientHelpOpen] = React.useState(false);

    const handleEfficientHelpOpen = (event) => {
        setEfficientHelpOpen(true);
        setanchortEfficientHelp(event.currentTarget);
    };
    
    const handleEfficientHelpClose = () => {
        setEfficientHelpOpen(false);
        setanchortEfficientHelp(null);
    };
    React.useEffect(()=>{
        //Calcualte Furnace BTU
        setBTUCalculated(CalculateHeat(props.sqft, efficient));
    }, [props.sqft,efficient])
    React.useEffect(()=>{
        //Get prices for base price and efficienty price. 
        if(props.type !=="0"){
        firebase.firestore().collection("Furnace").doc("base").get().then((doc) => {
            if(doc.exists){
                var data = doc.data();
                setCalcPrice(data.price);
                setFurnaceBasePrice(data.price);
            }
        })
        firebase.firestore().collection("Furnace").doc("efficient").get().then((doc) => {
            if(doc.exists){
                var data = doc.data();
                setEfficientPrice(data.price);
            }
        })
        }
        else{
            firebase.firestore().collection("Complete").doc("Furnace").get().then(doc=>{
                if(doc.exists){
                    var data = doc.data();
                    setFurnaceBasePrice(data.base);
                    setCalcPrice(data.base);
                    setEfficientPrice(data.efficient);
                }
            })
        }
        
        if(props.session!== null){
            var heatingData = props.session.Order.heatingData;
            setEfficient(heatingData.Efficient);
        }
    }, [props.session, props.type])
    React.useEffect(()=>{
        props.updatePrice(calcPrice);
    },[calcPrice, props])
    const saveData = props.saveData;
    React.useEffect(()=>{
        var heatingData={
            "BTU": BTUCalculated,
            "Efficient": efficient,
            "calcPrice": calcPrice,
            "EfficientIncease": efficientPrice
        }
        saveData(heatingData);
    },[calcPrice,efficientPrice,BTUCalculated,efficient, saveData])
  
    const updateEfficient = (e) =>{
        var newPrice;
        if(efficient){
            newPrice = furnaceBasePrice - efficientPrice;
        }
        else{
            newPrice = furnaceBasePrice + efficientPrice;
        }
        setEfficient(e.target.checked);
        setCalcPrice(newPrice);
    }
    return <Grid container spacing={2}>
        <Grid item lg={12} md={12} sm={12} xs={12}>
            <Typography variant="h6" >
                <Translate id="Order.Heating.RecommendedMessage"/> 
                <b>&nbsp;<NumberFormat value={BTUCalculated} displayType={'text'} thousandSeparator={true} suffix={' BTU'}/></b>
                </Typography>
        </Grid>
        <Grid item>
            <Typography > <Translate id="Order.Heating.EfficiencyLbl"/>  +${efficientPrice}</Typography>
            <Switch
            checked={efficient}
            onChange={updateEfficient}
            color="primary"
            name="checkedB"
            inputProps={{ 'aria-label': 'primary checkbox' }}
            />
             <IconButton
                        onClick={handleEfficientHelpOpen}
                        color="inherit">
                        <HelpIcon style={{marginLeft: "1%"}}/>
                    </IconButton>
                    <Popover
                disableRestoreFocus
                    open={efficientHelpOpen}
                    anchorEl={anchorefficientHelp}
                    onClose={handleEfficientHelpClose}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'center',
                    }}
                >
                    <Typography style={{padding:"1%"}}>
                        <Translate id="Order.Heating.EfficientHelpTxt"/>
                    </Typography>
                </Popover>
        </Grid>
        {props.type!=="0"? <Grid item lg={12} md={12} sm={12} xs={12}>
            <Typography >
                <Translate id="Order.Heating.PriceLable"/> 
                <b><NumberFormat value={calcPrice} displayType={'text'} decimalScale={1} thousandSeparator={true} prefix={'$'}/></b>
            </Typography>
        </Grid>
        :null}
    </Grid>
}