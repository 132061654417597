import React from "reactn";
import {
  Grid,
  Typography,
  Stepper,
  Step,
  StepLabel
} from "@material-ui/core";
export default function OrderView(props) {
  const [activeStep, setActiveStep] = React.useState(0);
  const steps = ["Requested", "In Progress", "Completed"]

  React.useEffect(()=>{
    switch (props.status) {
      case "Requested":
        setActiveStep(0);
        break;
      case "In Progress":
        setActiveStep(1);
        break;
      case "Complete":
        setActiveStep(2);
        break;
      default:
        break;
    }
  }, [props])
  return (
    <Grid container spacing={2}>
      <Grid item>
        <label>Status</label>
        <Stepper activeStep={activeStep}>
          {steps.map((label, index) =>
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
            </Step>
          )}
        </Stepper>
      </Grid>
      <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
        Address: {props.AddressData.AddressText}, {props.AddressData.zipCode}
      </Grid>

      <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
        {props.Order.sqftKnowldege ? (
          <Typography>Square Feet: <b>{props.Order.sqft}</b></Typography>
        ) : null}
      </Grid>
      {props.Order.coolingData !== "" ? (
        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>

          <Typography>Ton: {props.Order.coolingData.ton}</Typography>
          <Typography>SEER: {props.Order.coolingData.SEER}</Typography>
          {props.Order.coolingData.HeatPump ? <Typography>Heat Pump Requested</Typography> : null}
        </Grid>
      ) : null}
      {props.Order.heatingData !== "" ? (
        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
          <Typography>BTU: {props.Order.heatingData.BTU} </Typography>
          {props.Order.heatingData.Efficient ? <Typography>High Efficient (90%+) </Typography> : null}
        </Grid>
      ) : null}

      <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
        <Grid container>
          <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
            Subtotal: {props.Order.subTotal}
          </Grid>
          <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
            Tax: {props.Order.taxTotal}
          </Grid>
          <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
            Total: {props.Order.total}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}
