import React from "react";
export default function Logo(props) {
  return (
    <>
      <div
        style={{
          textAlign: "center", 
          paddingTop: props.nav? "":"10%",
          fontSize: props.nav ? "0.7rem" : "1.25rem",
          
          fontFamily: "pirulen, sans-serif",
          fontStyle: "bold",
          float: props.nav? "left":"",
          fontWeight: "700",
        }}
        className="notranslate"
      >
         
            <span style={{fontSize: props.nav? "1.68rem": "3rem"}}>NOVA</span>
            <br/>
            <span style={{ color: "orangered"}}>Heating</span>
            &nbsp;&&nbsp;
            <span style={{ color: "cornflowerblue"}}>Cooling</span>
      </div>
    </>
  );
}
