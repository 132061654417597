import React from 'reactn';
import { 
    makeStyles,
    Typography,
    AppBar,
    Toolbar,
    IconButton,
    List,
    ListItem,
    ListItemText,
    Link
} from '@material-ui/core/';
import Brightness4Icon from '@material-ui/icons/Brightness4';
import Brightness7Icon from '@material-ui/icons/Brightness7';
// import TranslateIcon from '@material-ui/icons/Translate';
// import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import Logo from './components/Logo';
import MenuIcon from '@material-ui/icons/Menu';
import firebase from 'firebase/app';
import { useHistory } from 'react-router-dom';
import { useAuthState } from 'react-firebase-hooks/auth';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import Divider from '@material-ui/core/Divider';
import {
BrowserView,
} from "react-device-detect";
// import LogoImg from './components/Logo/Logo.svg';


const useStyles = makeStyles((theme) => ({
    root: {
      display: 'flex',
    },
    toolbar: {
      paddingRight: 24, // keep right padding when drawer closed
      
    },
    toolbarIcon: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-end',
      padding: '0 8px',
      ...theme.mixins.toolbar,
    },
    appBar: {
      zIndex: theme.zIndex.drawer + 1,
      transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
    },
    menuButton: {
      marginRight: 36,
    },
    menuButtonHidden: {
      display: 'none',
    },
    title: {
      flexGrow: 1,
    },
    drawerPaperClose: {
      overflowX: 'hidden',
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      width: theme.spacing(7),
      [theme.breakpoints.up('sm')]: {
        width: theme.spacing(9),
      },
    },
    appBarSpacer: theme.mixins.toolbar,
    content: {
      flexGrow: 1,
      height: '100vh',
      overflow: 'auto',
    },
    container: {
      paddingTop: theme.spacing(4),
      paddingBottom: theme.spacing(4),
    },
    paper: {
      padding: theme.spacing(2),
      display: 'flex',
      overflow: 'auto',
      flexDirection: 'column',
    },
    fixedHeight: {
      height: 240,
    },
    link: {
      margin: theme.spacing(1, 1.5),
      color: "inherit",
      textDecoration: "none",
      "&:hover": {
        textDecoration: "underline"
    }
    },
  }));

export default function NavBar(props){
    let history = useHistory();
    const classes = useStyles();
    const [user] = useAuthState(firebase.auth());
    // const [anchorTranslation, setAnchorTranslation] = React.useState(null);
    const [ userInfo, setUserInfo ] = React.useGlobal('userInfo'); //UserInfo defined here

    const [drawerAnchor, setDrawerAnchor] = React.useState(null);

    // const handleTranslateClick = (event) => {
    //     setAnchorTranslation(event.currentTarget);
    // };
    const handleDrawerClick = (event) =>{
      setDrawerAnchor(true);
    }

    const handleDrawerClose=()=>{
      setDrawerAnchor(null);
    }
    // const handleTranslateClose = (key) => {
    //     if(props.languages[key]?.code !== undefined){
    //         props.updateLocale(props.languages[key].code);
    //     }
    //     setAnchorTranslation(null);
    // };
    React.useEffect(()=>{
      if(user != null){
        firebase.firestore().collection("USERS").doc(user.uid).get().then(doc=>{
          setUserInfo(doc.data());
        });
      }
    }, [user,setUserInfo]);
    const triggerSignOut = ()=>{
      firebase.auth().signOut(); 
      setUserInfo(null);
      history.push("/"); 
      handleDrawerClose();
    }
    return(
        <AppBar position="absolute">
            <Toolbar className={classes.toolbar} style={{backgroundColor: props.darkState? "": "white",color:props.darkState? "white": "black"}}>
            {/* <img src={LogoImg} style={{height: props.nav? "1.68rem": "3rem", display: "inline"}} alt="Nova Logo" /> */}
            <Typography component="h1" variant="h6" color="inherit" noWrap className={classes.title}>
                <Link href="/" variant="button" className={classes.link} ><Logo nav {...props}/></Link>
                
            </Typography>
            
            <BrowserView> 
            <nav style={{marign: "1%"}}>
              <Link href="/" variant="button" className={classes.link}>Home</Link>
              <Link href="/Services" variant="button" className={classes.link}>Services</Link>
              <Link href="/About" variant="button" className={classes.link}>About</Link>
              <Link href="/Contact" variant="button" className={classes.link}>Contact</Link>
              <Link href="/Warranty" variant="button" className={classes.link}>Warranty</Link>
              {userInfo !== null && userInfo !== undefined? <Link href="/Dashboard" variant="button" className={classes.link}>Dashboard</Link>: null}
                </nav>
            </BrowserView>


             <IconButton

                color="inherit"
                onClick={props.toggleDarkMode}
                style={{padding: "1%"}}
            >
                {props.darkState ?<Brightness7Icon/> : <Brightness4Icon  /> }
            </IconButton>
            {/* <Menu
                anchorEl={anchorTranslation}
                keepMounted
                open={Boolean(anchorTranslation)}
                onClose={handleTranslateClose}
            >
                {props.languages.map((lang, key) =>
                    <MenuItem key={key} onClick={() => handleTranslateClose(key)}>{lang.name}</MenuItem>
                )}
            </Menu> */}
            
            <IconButton
                edge="start"
                color="inherit"
                onClick={handleDrawerClick}
            >
                <MenuIcon /> 
            </IconButton>
            </Toolbar>

            <SwipeableDrawer
            
              anchor="right"
                open={Boolean(drawerAnchor)}
                onOpen={handleDrawerClick}
                onClose={handleDrawerClose}

              >
                 <List>
                   <ListItem button onClick={() =>{history.push("/"); handleDrawerClose();}}>
                     <ListItemText primary={"Home"}/>
                   </ListItem>
                   <ListItem button onClick={() =>{history.push("/Services"); handleDrawerClose();}}>
                     <ListItemText primary={"Services"}/>
                   </ListItem>
                   <ListItem button onClick={() =>{history.push("/About"); handleDrawerClose();}}>
                     <ListItemText primary={"About"}/>
                   </ListItem>
                   <ListItem button onClick={() =>{history.push("/Contact"); handleDrawerClose();}}>
                     <ListItemText primary={"Contact"}/>
                   </ListItem>
                   <ListItem button onClick={() =>{history.push("/Warranty"); handleDrawerClose();}}>
                     <ListItemText primary={"Warranty"}/>
                   </ListItem>
                  
                  {/* <ListItem button onClick={() =>{history.push("/NewEstimate?type=0"); handleDrawerClose();}}>
                     <ListItemText primary={props.translate("General.CompleteEstimateLbl")}/>
                   </ListItem>
                   <ListItem button onClick={() =>{history.push("/NewEstimate?type=1"); handleDrawerClose();}}>
                     <ListItemText primary={props.translate("General.ACEstimateLbl")}/>
                   </ListItem>
                   <ListItem button onClick={() =>{history.push("/NewEstimate?type=2"); handleDrawerClose();}}>
                     <ListItemText primary={props.translate("General.FurnaceEstimateLbl")}/>
                   </ListItem> */}
                   <Divider/>
                   <ListItem button onClick={() =>{history.push("/Account"); handleDrawerClose();}}>
                     <ListItemText primary={props.translate("General.AccountLbl")}/>
                   </ListItem>
                   
                   {userInfo !== null && userInfo !== undefined? <>
                    <ListItem button onClick={() =>{history.push("/Dashboard"); handleDrawerClose();}}>
                    <ListItemText primary={"Dashboard"} />
                  </ListItem>
                   <ListItem button onClick={triggerSignOut}>
                    <ListItemText primary={props.translate("General.SignOutLbl")} />
                  </ListItem>
                  </>
                  :null}
                </List>
            </SwipeableDrawer>
        </AppBar>
    )
}