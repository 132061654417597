import { Skeleton } from '@material-ui/lab';
import React from 'reactn';
import AdminView from './AdminView';
import CustomerView from './CustomerView';
import firebase from 'firebase/app'

export default function Index(props){
    const [teamMember, setTeamMember] = React.useState(false);
    const [userInfo] = React.useGlobal('userInfo');
    
    React.useEffect(()=>{
        if(userInfo !== null && userInfo !== undefined){
            if(userInfo.Info?.admin){
                setTeamMember(true);
            }
        }
    }, [userInfo])
    if(teamMember){
        //Return team member view
        return <div>
            <AdminView/>

        </div>
    }
    else if(userInfo !== undefined){
        return <div>
            <CustomerView user={firebase.auth()}/>
        </div>
    }
    else {
        return (<Skeleton/>)
    }
}