import { Grid, Typography, Link, TextField, Card, CardContent, Button } from '@material-ui/core';
import React from 'reactn';
import MuiPhoneNumber from 'material-ui-phone-number';
import validator from 'validator';


export default function Contact() {
    const [name, setName] = React.useState("");
    const [email, setEmail] = React.useState("");
    const [phone, setPhone] = React.useState("");
    const [message, setMessage] = React.useState("");
    const [validPhone, setValidPhone] = React.useState(true);
    const [validEmail, setValidEmail] = React.useState(true);
    const [APIURL] = React.useGlobal("APIURL");
    React.useEffect(() => {
        if (phone !== undefined) {
            setValidPhone(!validator.isMobilePhone(phone))
        }
        else {
            setValidPhone(true)
        }
    }, [phone])
    React.useEffect(() => {
        if (email !== undefined) {
            setValidEmail(!validator.isEmail(email))
        }
        else {
            setValidPhone(true)
        }
    }, [email])
    const sendEmail = () => {
        const payload = {
            to: "info@hvacnova.com",
            message: {
                subject: "Question from " + name,
                text: "Contact information: \nName: " + name + "\nEmail: " + email + " \nPhone: " + phone + "\n\nQuestion: " + message
            }
        }
        fetch(APIURL + "/api/newMessage", { method: 'POST', body: JSON.stringify(payload) })
    }
    return <Grid container spacing={3} justify="center" alignContent="center" >
        <Grid item lg={12} md={12} sm={12} xs={12}>
            <Typography align="center" variant="h2">Contact Us</Typography>
        </Grid>
        <Grid item lg={10} md={10} sm={10} xs={12}>
            <Typography align="center">
                The best way to contact us is through email at: <br /> <Link color="inherit" href="mailto:info@hvacnova.com">info@hvacnova.com</Link>
            </Typography>
        </Grid>
        <Grid item lg={10} md={10} sm={10} xs={10}>
            <Typography align="center">
                We will respond as soon as possible during our business hours.
            </Typography>
        </Grid>

        <Grid item lg={4} md={4} sm={6} xs={12}>
            <Card variant="outlined">
                <CardContent>
                    <Grid container spacing={2} justify="center">
                        <Grid item lg={12} md={12} sm={12} xs={12}>
                            <Typography variant="h4" align="center">
                                Business Hours
                    </Typography>
                        </Grid>
                        <Grid item lg={6} md={6} sm={6} xs={6}>
                            <Typography align="right">
                                Monday - Friday:
                    </Typography>
                        </Grid>
                        <Grid item lg={6} md={6} sm={6} xs={6}>
                            <Typography align="center">
                                8AM - 5PM
                    </Typography>
                        </Grid>
                        <Grid item lg={6} md={6} sm={6} xs={6}>
                            <Typography align="right">
                                Saturday - Sunday:
                    </Typography>
                        </Grid>
                        <Grid item lg={6} md={6} sm={6} xs={6}>
                            <Typography align="center">
                                Closed
                    </Typography>
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>
        </Grid>
        <Grid item lg={4} md={4} sm={10} xs={10}>
            <Card variant="outlined">
                <CardContent>
                    <Grid container spacing={3} width={"100%"}>
                        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                            <Typography variant="h4">Get in touch</Typography>
                        </Grid>
                        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                            <TextField style={{ width: "100%" }} error={name.length !== 0 && name.length <= 2} onChange={e => setName(e.target.value)} label="Full Name" variant="outlined" />
                        </Grid>
                        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                            <TextField style={{ width: "100%" }} error={validEmail && email.length !== 0} onChange={e => setEmail(e.target.value)} label="Email" variant="outlined" />
                        </Grid>
                        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                            <MuiPhoneNumber defaultCountry={'us'}
                                error={validPhone && phone.length !== 0}
                                value={phone}
                                fullwidth="true"
                                variant="outlined"
                                disableDropdown
                                countryCodeEditable={false} 
                                onChange={(e) => { setPhone(e) }}
                                label="Phone Number" />
                        </Grid>
                        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                            <TextField multiline style={{ width: "100%" }} error={message.length < 2 && message.length !== 0} onChange={e => setMessage(e.target.value)}
                                rows={4} label={"Message (" + message.length + "/200)"} inputProps={{ maxLength: 200 }} variant="outlined" />
                        </Grid>
                        <Grid item>
                            <Button onClick={sendEmail} disabled={validPhone || validEmail || name.length < 2 || message.length < 2} variant="contained">Sumbit</Button>
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>
        </Grid>
    </Grid>
}