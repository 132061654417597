import React, { useState,Suspense } from "react";
// import firebase from 'firebase/app';
// import 'firebase/database';
import { 
    makeStyles,
    Container,
    Paper,
    Link,
    Box,    
    Typography,
    CircularProgress,
    Grid,
    CssBaseline,
    ThemeProvider } from '@material-ui/core';
import Theme from './Theme';
import NavBar from "./NavBar";
import LanguageCodes from './LanguageCodes.json';
function Copyright() {
    return (
      <Typography variant="body2" color="textSecondary" align="center">
        {'Copyright © '}
        <Link color="inherit" href="#">
            Nova Heating and Cooling
        </Link>{' '}
        {new Date().getFullYear()}
        {'.'}
      </Typography>
    );
  }

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  toolbar: {
    paddingRight: 24, // keep right padding when drawer closed
  },
  toolbarIcon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0 8px',
    ...theme.mixins.toolbar,
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  menuButtonHidden: {
    display: 'none',
  },
  title: {
    flexGrow: 1,
  },
  drawerPaperClose: {
    overflowX: 'hidden',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing(7),
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9),
    },
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: '100vh',
    overflow: 'auto',
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
  fixedHeight: {
    height: 240,
  },
}));
export default function Layout(props) {
    const classes = useStyles();
    const [locale, setLocale] = React.useState('enUS');
    const [darkState, setDarkState] = useState(false);
    const [languageLoaded, setLanguageLoaded] = React.useState(false);

    React.useEffect(()=>{
      if (window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches) {
          // dark mode
          setDarkState(true);
      }
    },[])
    const handleThemeChange = () => {
        setDarkState(!darkState);
    };
    const setLang = React.useCallback((langData) =>{
        //Find language for material ui
        var code = LanguageCodes[langData].code;
        setLocale(code); //Set Material UI Language
        props.setActiveLanguage(langData); //Set site language
    },[props])
    React.useEffect(()=>{
        if(!languageLoaded && props.languages.length > 0){
            props.languages.forEach(language =>{
                if(language.code === navigator.language)
                { 
                    setLang(language.code);
                }
            });
            setLanguageLoaded(true);
        }
    },[props.languages, languageLoaded,setLang])
    return (
        <Suspense fallback={
            <CircularProgress 
                justify="center"
                style={{marginTop: "25%",marginBottom: "30%", marginLeft: "50%"}} 
                color="inherit"/>}>
            <ThemeProvider theme={Theme({darkState: darkState, locale: locale})}>
                <div className={classes.root}>
                <CssBaseline />
                <NavBar {...props} darkState={darkState} toggleDarkMode={handleThemeChange} locale={locale} updateLocale={setLang}/>
                <main className={classes.content}>
                    <div className={classes.appBarSpacer} />
                    <Container maxWidth="lg" className={classes.container} >
                    <Grid container spacing={3} alignItems="center" justify="center">
                        <Grid item xs={12} md={10} lg={10}>
                        <Paper>
                            {props.children}
                        </Paper>
                        </Grid>
                    </Grid>
                    <Box pt={4}>
                        <Copyright />
                    </Box>
                    </Container>
                </main>
                </div>
            </ThemeProvider>
        </Suspense>
    );
}


