import React from 'reactn';
import firebase from 'firebase/app';
import { useAuthState } from 'react-firebase-hooks/auth';
import { useCollection } from "react-firebase-hooks/firestore";
import MUIDataTable from "mui-datatables";
import {
    Button,
    Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions
} from '@material-ui/core';
import OrderView from './OrderView';
function formatDate(date) {
    var d = new Date(date);
    return " " + d.toLocaleString();
}
export default function CustomerView() {
    const [user] = useAuthState(firebase.auth());
    const [viewOrder, setViewOrder] = React.useState(false);
    const [selectedOrder, setSelectedOrder] = React.useState(null);
    const [data, dataLoading] = useCollection(
        firebase.firestore().collection("ORDERS").where("UID", "==", user?.uid),
        {
            snapshotListenOptions: { includeMetadataChanges: true },
        }
    );
    const [ordersObj, setOrdersObj] = React.useState({});
    const [orders, setOrders] = React.useState([]);
    const columns = ["Date", "Service", "Status"];
    //Get order list
    React.useEffect(() => {
        if (!dataLoading) {
            var ordersList = [];
            var orderObjHolder = {};
            data.docs.forEach((doc) => {
                var tempData = doc.data();
                var type;
                tempData.id = doc.id;
                if (tempData.status === undefined) {
                    tempData.status = "Requested";
                }
                if (tempData.coolingData !== "" && tempData.heatingData !== "") {
                    type = "A/C & Furnace";
                }
                else if (tempData.coolingData !== "") {
                    type = "A/C";
                }
                else if (tempData.heatingData !== "") {
                    type = "Furnace";
                }

                var newItem = [
                    formatDate(tempData.Order.timestamp),
                    type,
                    tempData.status,
                    doc.id,
                ];
                orderObjHolder[doc.id] = tempData;
                ordersList.push(newItem);
            });
            //Save to state
            setOrdersObj(orderObjHolder);
            setOrders(ordersList);
        }
    }, [dataLoading, data]);
    const options = {
        filterType: "dropdown",
        responsive: "standard",
        tableBodyHeight: "100%",
        selectableRowsHideCheckboxes: true,
        selectableRows: "none",
        print: false,
        download: false,
        onRowClick: (event, rowData) => {
            //On row click do stuff
            //Find the object by id and then save it to the state.
            var orderId =
                orders[rowData.rowIndex][orders[rowData.rowIndex].length - 1];
            setSelectedOrder(ordersObj[orderId]);
            setViewOrder(!viewOrder);
        },
        sortOrder: { name: "Date", direction: "desc" },
    };
    return <div>
        <MUIDataTable
            title={"Orders"}
            data={orders}
            columns={columns}
            options={options}
        />
        <Dialog open={viewOrder} onClose={() => setViewOrder(!viewOrder)}>
            <DialogTitle>
                Order for{" "}
                {selectedOrder !== null
                    ? selectedOrder.ContactInformation.name
                    : null}
            </DialogTitle>
            <DialogContent>
                <DialogContentText style={{ padding: 2 }}>
                    <OrderView {...selectedOrder} />
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button variant="contained" onClick={() => setViewOrder(!viewOrder)}>Close</Button>
            </DialogActions>
        </Dialog>
    </div>
}