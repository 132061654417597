import React from 'react';
import firebase from 'firebase/app';
import { useCollection } from 'react-firebase-hooks/firestore';
import Pricing from '../Pricing/Pricing';
import 'firebase/firestore';


export default function Services(props){
    // const classes = useStyles();
    const [services, setServices] = React.useState([]);
    const [snapshotFull, loadingFull] = useCollection(firebase.firestore().collection("Complete"));
    const [snapshotAC, loadingAC] = useCollection(firebase.firestore().collection("AC"));
    const [snapshotFurnace, loadingFurnace] = useCollection(firebase.firestore().collection("Furnace"));
    React.useEffect( ()=>{
        async function updateData(){
        if(!loadingAC && !loadingFurnace && !loadingFull){
            ///Get price for a full install
            var minFullPrice = null;
            snapshotFull.forEach(snap=>{
                if(snap.id==="AC"){
                    minFullPrice += snap.data()["2"];
                }
                else{
                    minFullPrice += snap.data().base;
                }
            })
            //Get AC min price
            var minACPrice = null;
            snapshotAC.forEach(snap=>{
                var price = snap.data().price;
                //if min isn't set yet lets save it. 
                if(minACPrice === null){
                    minACPrice = price;
                }
                else{
                    //if min price is greater then save the current as smallest. 
                    if(minACPrice > price){
                        minACPrice = price;
                    }
                }
            })

            //Get Furnace min price
            var minFurnacePrice = null;
            snapshotFurnace.forEach((snap, key)=>{
                //Grab the base price since we don't change the price based on size.
                if(snap.id === "base"){
                    minFurnacePrice = snap.data().price;
                }
            })    
            setServices([
                {
                    id:0,
                    title: "A/C & Furnace",
                    minPrice: minFullPrice,
                    description: [
                        "All A/C Features", 
                        "All Furnace Features",
                ],
                    buttonText: "Get an estimate",
                    buttonVariant: 'contained',
                },
                {
                    id:1,
                    title: "A/C",
                    minPrice: minACPrice,
                    description: [
                        "1 Year labor warranty",
                        "Up to 12 years warranty on parts"
                    ],
                    buttonText: "Get an estimate",
                    buttonVariant: 'contained',
                },
                {
                    id:2,
                    title: "Furnace",
                    minPrice: minFurnacePrice,
                    description: [
                        "80% or 90% efficient",
                        "1 Year labor warranty"
                    ],
                    buttonText: "Get an estimate",
                    buttonVariant: 'contained',
                },
                // {
                //     id:3,
                //     title: "Service Calls",
                //     description: [
                       
                //     ],
                //     buttonText: "Coming Soon",
                //     buttonVariant: 'contained',
                // }
              ]);
        }
        }
        updateData();
    },[props,loadingFull,loadingFurnace, loadingAC,snapshotFull, snapshotAC, snapshotFurnace])
    
    return (<>
        <Pricing services={services} title="Services" description={"We currently provide the following installations or replacements for residential homes"} {...props}/>
        {/* <Pricing services={services} title={"Commercial"} {...props}/> */}
      </>
    )
}