import { Grid, Typography } from "@material-ui/core";
import Story from "./Story";


export default function About() {
    return <Grid container justify="center" spacing={3} alignContent="center">
        <Grid item lg={12} md={12} sm={12} xs={12}>
            <Typography align="center" variant="h2">About Us</Typography>
        </Grid>
        <Grid item lg={8} md={10} sm={10} xs={10}>
            <Story/>
        </Grid>
    </Grid>
}