import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import StepContent from '@material-ui/core/StepContent';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import {Translate} from 'react-localize-redux';
import Step1 from './Step1';
import Step2 from './Step2';
import Step3 from './Step3';
import { useHistory } from 'react-router-dom';
import firebase from 'firebase/app';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { IconButton } from '@material-ui/core';
///Residential 

const useAuth = () => {
    const fireUser = firebase.auth().currentUser;
    const [user, setUser] = React.useState(fireUser);
    

    React.useEffect(() => {
        const unsub = firebase.auth().onAuthStateChanged((user) => {
        user ? setUser(user) : setUser(null);
        });
        return () => {
        unsub();
        };
    });
return user;
};

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  button: {
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  actionsContainer: {
    marginBottom: theme.spacing(2),
  },
  resetContainer: {
    padding: theme.spacing(3),
  },
}));


function getStepContent(step, props, validCallback) {
  switch (step) {
    case 0:
      return <Step1 {...props} validCallback={validCallback} step={step}/>;
    case 1:
      return <Step2 {...props} validCallback={validCallback} step={step}/>;
    case 2:
      return <Step3 {...props} validCallback={validCallback} step={step}/>;
    default:
      return 'Unknown step';
  }
}

export default function NewEstimate(props) {
  const classes = useStyles();
  const [activeStep, setActiveStep] = React.useState(0);
  const steps = [props.translate("Order.Step1"), props.translate("Order.Step2"), props.translate("Order.Step3")]
  const [valid, setValid] = React.useState(false);
  let history = useHistory();
  const user = useAuth();  
  const handleNext = () => {
    setValid(false);
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const validCallback = (validTemp, stepInput)=>{
    if(stepInput !== activeStep){return;} //Prevent other steps from updating the validation
    setValid(validTemp);
    
  }
  const submitOrder = () =>{
    var session =  JSON.parse(sessionStorage.getItem('session'));
    if(user !== null){
        session["UID"] = user.uid;

    }
    session["emailTemplate"] = {
      "to": session.ContactInformation.email,
      "message": {
        subject: props.translate("EmailSender.OrderSentSubject"),
        text: "Hey " + session.ContactInformation.name + "this is an email to confirm that your estimate request was received. We will contact you in the next couple days. If you have any questions please email us at info@hvacnova.com"
      }
    }
    //Save users name, email, and phone.
    firebase.firestore().collection("USERS").doc(session.UID).set({Info: session.ContactInformation});
    firebase.firestore().collection("ORDERS").doc().set(session);
    handleNext();
  }
  const sendHome=()=>{
      sessionStorage.clear('session');
      history.push("/");
  }
  React.useEffect(()=>{
    document.body.scrollTop = document.documentElement.scrollTop = 0;
  },[]);
  return (
    <div className={classes.root}>
      <div>
        <IconButton
                    onClick={sendHome}
                    color="inherit"
                >
                    <ArrowBackIcon/>
                    <Typography>
                      &nbsp;
                      <Translate id="General.Homelbl"/>
                    </Typography>
                </IconButton> 
      </div>
      <Stepper activeStep={activeStep} orientation="vertical">
        {steps.map((label, index) => (
          <Step key={label}>
            <StepLabel>{label}</StepLabel>
            <StepContent>
              <Typography>{getStepContent(index, props, validCallback)}</Typography>
              <div className={classes.actionsContainer}>
                <div>
                  <Button
                    disabled={activeStep === 0}
                    onClick={handleBack}
                    className={classes.button}
                  >
                    {props.translate("General.BackLbl")}
                  </Button>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={activeStep=== steps.length-1? submitOrder : handleNext}
                    className={classes.button}
                    disabled={!valid}
                  >
                    {activeStep === steps.length - 1 ? props.translate("General.SubmitLbl") : props.translate("General.NextLbl")}
                  </Button>
                </div>
              </div>
            </StepContent>
          </Step>
        ))}
      </Stepper>
      {activeStep === steps.length && (
        <Paper square elevation={0} className={classes.resetContainer}>
          <Typography><Translate id="Order.CompletedOrderMsg"/></Typography>
          <Button onClick={sendHome} variant="contained" color="primary" className={classes.button}>
            <Translate id="General.OkayBtnLbl"/>
          </Button>
        </Paper>
      )}
    </div>
  );
}
