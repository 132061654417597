import React, { useCallback } from 'reactn';
import {  Grid, Button, Typography,TextField, Card, CardContent } from '@material-ui/core';
import { Translate } from 'react-localize-redux';
import StyledFirebaseAuth from 'react-firebaseui/StyledFirebaseAuth';
import firebase from 'firebase/app';
import MuiPhoneNumber from 'material-ui-phone-number';
import validator from 'validator';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import parse from 'autosuggest-highlight/parse';
import throttle from 'lodash/throttle';
import Geocode from "react-geocode";
import Autocomplete from '@material-ui/lab/Autocomplete';
import NumberFormat from 'react-number-format';
function loadScript(src, position, id) {
    if (!position) {
      return;
    }
  
    const script = document.createElement('script');
    script.setAttribute('async', '');
    script.setAttribute('id', id);
    script.src = src;
    position.appendChild(script);
  }
  
  const autocompleteService = { current: null };
// Configure FirebaseUI.
const uiConfig = {
    // Popup signin flow rather than redirect flow.
    signInFlow: 'popup',
    // Redirect to /signedIn after sign in is successful. Alternatively you can provide a callbacks.signInSuccess function.
    //signInSuccessUrl: '/',
    // We will display Google and Facebook as auth providers.
    signInOptions: [
      firebase.auth.GoogleAuthProvider.PROVIDER_ID,
      firebase.auth.EmailAuthProvider.PROVIDER_ID, 
    //   firebase.auth.FacebookAuthProvider.PROVIDER_ID,
    ],
    callbacks: {
        // Avoid redirects after sign-in.
        signInSuccessWithAuthResult: () => false,
      },
  };
const useAuth = () => {
    const fireUser = firebase.auth().currentUser;
    const [user, setUser] = React.useState(fireUser);
    

    React.useEffect(() => {
        const unsub = firebase.auth().onAuthStateChanged((user) => {
        user ? setUser(user) : setUser(null);
        });
        return () => {
        unsub();
        };
    });
return user;
};
export default function Step3(props){
    const [validation, setValidation] = React.useState(false);
    const [guestMode, setGuestMode] = React.useState(false);
    const [contactValid, setContactValid] = React.useState(false);
    const [addressValid, setAddressValid] = React.useState(false);
    const [total, setTotal] = React.useState(0);
    const [name, setName] = React.useState();
    const [email, setEmail] = React.useState();
    const [validEmail, setValidEmail] = React.useState(true);
    const [phone, setPhone] = React.useState();
    const [validPhone, setValidPhone] = React.useState(true);
    const [showPrice, setShowPrice] = React.useState(false);
    const [userInfo] = React.useGlobal('userInfo');
    const [value, setValue] = React.useState("");
    const [inputValue, setInputValue] = React.useState('');
    const [options, setOptions] = React.useState([]);
    const loaded = React.useRef(false);
    const [city, setCity] = React.useState("");
    const [state, setState] = React.useState("");
    const [zip, setZip] = React.useState("");

    // const [sessionData, setSessionData] = React.useState(null);
    const [addressData, setAddressData] = React.useState(null);
    const [zipData, setZipData] = React.useState(null);
    const apikey = 'AIzaSyDD-JHHVcsHYqUBIxzyem0YP8BZaRXYdNU';

    const user = useAuth();
    React.useEffect(()=>{
        if(userInfo === undefined){
            //First Time user. Grab name from google
            setName(user?.displayName);
            setEmail(user?.email);
        }
        else{
            //Returning user. Grab name, email, and phone.
            setName(userInfo.Info.name);
            setEmail(userInfo.Info.email);
            setPhone(userInfo.Info.phone);
        }

    }, [userInfo,user])
    React.useEffect(()=>{
        var session = sessionStorage.getItem('session');
        var sessionData = JSON.parse(session);
        if(sessionData===null){return;}
        sessionData["guestMode"] = guestMode;
        sessionStorage.setItem('session',JSON.stringify(sessionData));
    },[guestMode])
    React.useEffect(() => {
        setValidation(addressValid && contactValid);
    }, [addressValid, contactValid])
    //Get price data 
    React.useEffect(() => {
        var session = sessionStorage.getItem('session');
        var sessionData = JSON.parse(session);
        if(sessionData===null){return;}
        setTotal(sessionData.Order.total);
        setShowPrice(sessionData.Order.sqftKnowldege);
        
    },[])
    React.useEffect(() => {
        var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
        if (!pattern.test(email)) {
            setValidEmail(true);
        }
        else{
            setValidEmail(false)}
    }, [email])
    React.useEffect(() =>{
        if(phone !== undefined){
            setValidPhone(!validator.isMobilePhone(phone))
        }
        else{
            setValidPhone(true)
        }
    },[phone])
    const saveValidation=useCallback(()=>{
        if(props.step !== 2){return;}
        props.validCallback(validation, props.step);
    },[props, validation])
    React.useEffect(() => {
        var session = sessionStorage.getItem('session');
        var sessionData = JSON.parse(session);
        if(sessionData===null){return;}
        var data ={
          "AddressText": addressData?.description, 
          "zipCode": zip
        }
        sessionData["AddressData"] = data;
        var currentUserInfo = {"email": email, "name": name, "phone": phone};
        sessionData["ContactInformation"] = currentUserInfo;
        sessionStorage.setItem('session',JSON.stringify(sessionData));
        setContactValid(!validEmail && !validPhone && name?.length>0 );
        saveValidation();
      }, [zip,email, name, phone,validEmail,validPhone,saveValidation,addressData, zipData])

    if (typeof window !== 'undefined' && !loaded.current) {
        if (!document.querySelector('#google-maps')) {
          loadScript(
            'https://maps.googleapis.com/maps/api/js?key=+'+apikey+'&libraries=places&fields=zip',
            document.querySelector('head'),
            'google-maps',
          );
        }
    
        loaded.current = true;
      }
      
  
      const fetch = React.useMemo(
        () =>
          throttle((request, callback) => {
            autocompleteService.current.getPlacePredictions(request, callback);
          }, 200),
        [],
      );
      React.useEffect(() => {
        let active = true;
    
        if (!autocompleteService.current && window.google) {
          autocompleteService.current = new window.google.maps.places.AutocompleteService();
        }
        if (!autocompleteService.current) {
          return undefined;
        }
    
        if (inputValue === '') {
          setOptions(value ? [value] : []);
          return undefined;
        }
    
        fetch({ input: inputValue }, (results) => {
          if (active) {
            let newOptions = [];
    
            if (value) {
              newOptions = [value];
            }
    
            if (results) {
              newOptions = [...newOptions, ...results];
            }
    
            setOptions(newOptions);
          }
        });
    
        return () => {
          active = false;
        };
      }, [value, inputValue, fetch]);
      const getZipCode = (addressInformation) =>{
          var addressData =  addressInformation.terms[0].value + "+" + addressInformation.terms[1].value + "+" +addressInformation.terms[2].value +"+"+addressInformation.terms[3].value +"+"+addressInformation.terms[4].value;
          Geocode.setApiKey(apikey);
          Geocode.enableDebug();
          // Zip from address.
          Geocode.fromAddress(addressData).then(
              response => {
                  setZipData(response.results[0]);
                  response.results[0].address_components.forEach(item => {
                      if(item.types[0] === "postal_code"){
                          setZip(item.long_name)
                      }
                  })
              },
              error => {
                console.error(error);
              }
          );
      }
    return (
        <Grid container spacing={2}  alignItems="center" justify="center" style={{padding:"2%"}} >
            <Grid item lg={12} md={12} sm={12} xs={12}>
            <Typography><Translate id="Order.Information.ContactInfoLbl"/></Typography>
            </Grid>
                {user === null && !guestMode? 
                     <Grid item lg={12} md={12} sm={12} xs={12} align="center">
                         <Card variant="outlined" fullwidth={true}>
                            <CardContent>
                        <StyledFirebaseAuth uiConfig={uiConfig} firebaseAuth={firebase.auth()} />
                        <Button variant="contained" onClick={() => {setGuestMode(true)}} style={{ width:"auto", marginTop: "4%"}}><Translate id="Order.GuestButtonLbl"/></Button>
                        </CardContent>
                        </Card>
                    </Grid>
                
                : 
                    <Grid item lg={12} md={12} sm={12} xs={12} align="center">
                        <Card variant="outlined">
                            <CardContent>
                        
                        <Grid container spacing={3} style={{padding:"2%"}} alignItems="center" justify="center" align="center" width="100%" >
                            <Grid item lg={12} md={12} sm={12} xs={12} >
                            {guestMode? 
                                <Button variant="contained" onClick={() => {setGuestMode(false)}} > <Translate id="Order.SignInSocialButtonLbl"/></Button>
                                :
                                <Button variant="contained" onClick={() => {firebase.auth().signOut()}} ><Translate id="General.SignOutLbl"/></Button>
                            }
                            </Grid>
                            <Grid item lg={12} md={12} sm={12} xs={12}>
                                <TextField variant="outlined" 
                                error={!name?.length > 0}
                                value={name} 
                                fullwidth="true"
                                onChange={(e)=>{setName(e.target.value)}} 
                                label={props.translate("General.NameLbl")}/>
                            </Grid>
                            <Grid item lg={12} md={12} sm={12} xs={12} >
                                <TextField variant="outlined" 
                                error={validEmail}
                                value={email} 
                                fullwidth="true"
                                onChange={(e)=>{setEmail(e.target.value)}} 
                                label={props.translate("General.EmailLbl")}/>
                            </Grid>
                            <Grid item lg={12} md={12} sm={12} xs={12} >
                                <MuiPhoneNumber defaultCountry={'us'} 
                                error={validPhone}
                                value={phone}
                                fullwidth="true"
                                variant="outlined" 
                                disableDropdown
                                countryCodeEditable={false} 
                                onChange={(e)=>{setPhone(e)}} 
                                label={props.translate("General.PhoneLbl")}/>
                            </Grid>
                        </Grid>
                        </CardContent>
                        </Card>
                    </Grid>
                 }
            <Grid item lg={12} md={12} sm={12} xs={12}>
                <Card variant="outlined">
                    <CardContent>
                        <Grid container spacing={3} style={{padding:"2%"}}  >
                            <Grid item >
                                <Typography><Translate id="Order.Information.AddressInfo"/></Typography>
                            </Grid>
                            <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                                    
                                    <Autocomplete
                                        id="google-map-demo"
                                        getOptionLabel={(option) => {
                                            return(typeof option === 'string' ? option : option.description)
                                        }}
                                        filterOptions={(x) => x}
                                        options={options}
                                        autoComplete
                                        includeInputInList
                                        filterSelectedOptions
                                        value={value}
                                        renderInput={(params) => (
                                            <TextField {...params} label={props.translate("Order.Information.AddressLbl")} variant="outlined" fullWidth />
                                        )}
                                        onChange={(event, newValue) => {
                                            setOptions(newValue ? [newValue, ...options] : options);
                                            if(newValue === null){
                                                setZip("");
                                                setValue("");
                                                setCity("");
                                                setState("");
                                                setAddressValid(false);
                                            }
                                            else{
                                                setAddressData(newValue);
                                                //Set Address Information
                                                getZipCode(newValue);
                                                setValue(newValue.terms[0].value + " " + newValue.terms[1].value);
                                                setCity(newValue.terms[2].value);
                                                setState(newValue.terms[3].value);
                                                setAddressValid(true);
                                            }
                                            
                                        }}
                                        onInputChange={(event, newInputValue) => {
                                            setInputValue(newInputValue);
                                        }}
                                    
                                        renderOption={(option) => {
                                            const matches = option.structured_formatting.main_text_matched_substrings;
                                            const parts = parse(
                                            option.structured_formatting.main_text,
                                            matches.map((match) => [match.offset, match.offset + match.length]),
                                            );

                                            return (
                                            <Grid container alignItems="center">
                                                <Grid item>
                                                <LocationOnIcon />
                                                </Grid>
                                                <Grid item xs>
                                                {parts.map((part, index) => (
                                                    <span key={index} style={{ fontWeight: part.highlight ? 700 : 400 }}>
                                                    {part.text}
                                                    </span>
                                                ))}

                                                <Typography variant="body2" color="textSecondary">
                                                    {option.structured_formatting.secondary_text}
                                                </Typography>
                                                </Grid>
                                            </Grid>
                                            );
                                        }}
                                        />
                                </Grid>
                                <Grid item lg={4} md={4} sm={6} xs={6}>
                                    <TextField label={props.translate("Order.Information.CityLbl")} 
                                        value={city} 
                                        error={!city?.length >0}
                                        onChange={(e) => setCity(e.target.value)} 
                                        variant="outlined" fullwidth/>
                                </Grid>
                                <Grid item lg={4} md={4} sm={6} xs={6}>
                                    <TextField error={!state?.length >0} label={props.translate("Order.Information.StateLbl")} value={state} onChange={(e) => setState(e.target.value)} variant="outlined" fullwidth/>
                                </Grid>
                                <Grid item lg={4} md={4} sm={6} xs={6}>
                                    <TextField error={!zip?.length >0} label={props.translate("Order.Information.ZipLbl")} value={zip} onChange={(e) => setZip(e.target.value)} variant="outlined" fullwidth/>
                                </Grid>
                                    
                            </Grid>
                        </CardContent>
                    </Card>
                </Grid>
                {showPrice? 
                <Grid item lg={12} md={12} sm={12} xs={12}>
                <Card variant="outlined">
                    <CardContent>
                        <Grid container spacing={2}>
                            <Grid item lg={8} xl={8} md={8} xs={7}>
                            <Typography >
                                <Translate id="Order.EstimateTotallbl"/>
                            </Typography>
                            </Grid>
                            <Grid item lg={4} xl={4} md={4} xs={4}>
                                <Typography>
                                <b><NumberFormat value={total} displayType={'text'} decimalScale={2} thousandSeparator={true} prefix={'$'}/></b>
                                </Typography>
                            </Grid>
                        </Grid>
                    </CardContent>
                </Card>
            </Grid> :null}
        </Grid> 
    )
}