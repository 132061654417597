import React from 'reactn';
import { Grid, Switch, Typography } from '@material-ui/core';
import NumberFormat from 'react-number-format';
export default function HeatingEdit(props) {
    const [efficient, setEfficient] = React.useState(props.efficient);
    const updateEfficient = (e) => {
        setEfficient(e.target.checked);
    }
    return <Grid container spacing={2}>
        <Grid item lg={12} md={12} sm={12} xs={12}>
            <Typography>
                Furnace Size:
            <b>&nbsp;<NumberFormat value={props.BTU} displayType={'text'} thousandSeparator={true} suffix={' BTU'} /></b>
            </Typography>
        </Grid>
        <Grid item>
            <Typography >High Efficient (90%)</Typography>
            No
            <Switch
                checked={efficient}
                onChange={updateEfficient}
                name="checkedB"
                disabled={true}
            />
            Yes
        </Grid>
    </Grid>
}