import React from 'reactn';
import Layout from './Layout';
import English from "./translations/en-US.translations.json";
import Spanish from "./translations/es-ES.translations.json";
import { renderToStaticMarkup } from "react-dom/server";
import { withLocalize } from "react-localize-redux";
// import { Translate } from "react-localize-redux";
import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";
import Home from './components/HomePage/Home';
import NewEstimate from './components/Estimate/NewEstimate';
import Account from './components/Account/Account';
import Dashboard from './components/Dashboard';
import Contact from './components/Contact/Contact';
import About from './components/About/About';
import Services from './components/Services/Services';
import Warranty from './components/Warranty/Warranty';

class App extends React.Component {
  constructor(props) {
    super(props);
    var APIURL = "https://hvacnova.com" 
    if (window.location.hostname !== "hvacnova.com") {
      APIURL = "https://dev.hvacnova.com";
    }
    else 
    this.setGlobal({APIURL: APIURL});
    this.props.initialize(
      {
        languages: [
          { name: "English", code: "en-US" },
          { name: "Español", code: "es-ES" }
        ],
        options: { renderToStaticMarkup },
        defaultLanguage: "es-ES"
      });
    this.props.addTranslationForLanguage(English, "en-US");
    this.props.addTranslationForLanguage(Spanish, "es-ES");
  }
  addTranslationsForActiveLanguage() {
    const {activeLanguage} = this.props;
    if (!activeLanguage) {
      return;
    }
    import(`./translations/${activeLanguage.code}.translations.json`) 
      .then(translations => {
        this.props.addTranslationForLanguage(translations, activeLanguage.code)
      }).catch(error => {
        console.error(error);
      });
  }
  render() {
    return <Router>
      <Layout {...this.props}> 
       <Switch>
          <Route exact path="/">
            <Home {...this.props}/>
          </Route>
          <Route exact path="/Services">
            <Services/>
          </Route>
          <Route exact path="/NewEstimate">
            <NewEstimate {...this.props}/>
          </Route>
          <Route exact path="/Account">
            <Account {...this.props}/>
          </Route>
          <Route exact path="/Dashboard">
            <Dashboard {...this.props}/>
          </Route>
          <Route exact path="/Contact">
            <Contact/>
          </Route>
          <Route exact path="/About">
            <About/>
          </Route>
          <Route exact path="/Warranty">
            <Warranty/>
          </Route>
        </Switch>
    </Layout>
    </Router>
  }
}

export default withLocalize(App);