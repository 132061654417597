import { useAuthState } from 'react-firebase-hooks/auth';
import React from 'react';
import firebase from 'firebase/app';
import {
    Grid,
    Card,
    CardContent
}from '@material-ui/core';
import StyledFirebaseAuth from 'react-firebaseui/StyledFirebaseAuth';
// import {Translate} from 'react-localize-redux';
import AccountEdit from './AccountEdit';
const uiConfig = {
    // Popup signin flow rather than redirect flow.
    signInFlow: 'popup',
    // Redirect to /signedIn after sign in is successful. Alternatively you can provide a callbacks.signInSuccess function.
    //signInSuccessUrl: '/',
    // We will display Google and Facebook as auth providers.
    signInOptions: [
      firebase.auth.GoogleAuthProvider.PROVIDER_ID,
      firebase.auth.EmailAuthProvider.PROVIDER_ID, 
    //   firebase.auth.FacebookAuthProvider.PROVIDER_ID,
    ],
    callbacks: {
        // Avoid redirects after sign-in.
        signInSuccessWithAuthResult: () => false,
      },
  };
export default function Account(props){
    const [user] = useAuthState(firebase.auth());
    return <Grid container>
        {user !== null?
        <AccountEdit {...props} user={user}/>
        :
        <Grid item lg={12} md={12} sm={12} xs={12} align="center">
            <Card variant="outlined" fullwidth="true">
                <CardContent>
                    <StyledFirebaseAuth uiConfig={uiConfig} firebaseAuth={firebase.auth()} />
                </CardContent>
            </Card>
        </Grid>
        }

    </Grid>
}