import { Grid, Typography } from "@material-ui/core";


export default function Warranty(){
    return <Grid container justify="center">
        <Grid item>
            <Typography variant="h2">
                Warranty Policy
            </Typography>
           
        </Grid>
        <Grid item>
        <Typography>
                We offer a 1 Year labor warranty on all of our installations. Which is starts from the date we completed the job. Machines have additional warrantys that are handled by the manufacture that can be 5 or even 10 years. 
            </Typography>
        </Grid>
    </Grid>
}