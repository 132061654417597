import { Grid, Box } from "@material-ui/core";
import PropTypes from 'prop-types';
import React from "reactn";
import OrderList from './AdminViews/OrderList';


function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`full-width-tabpanel-${index}`}
        aria-labelledby={`full-width-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box p={3}>
            {children}
          </Box>
        )}
      </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired
};

// function a11yProps(index) {
//     return {
//       id: `scrollable-auto-tab-${index}`,
//     //   'aria-controls': `scrollable-auto-tabpanel-${index}`,
//     };
//   }

export default function AdminView() {
    // const [tabValue, setTabValue] = React.useState(0);

  // const handleTabChange = (event, newValue) => {
  //   setTabValue(newValue);
  // };
  return (
    <Grid container justify="center">
      {/*<Grid item>
         <Paper square>
        <Tabs
          value={tabValue}
          onChange={handleTabChange}
          variant="fullWidth"
          scrollButtons="auto"
        >
          <Tab label="Orders" {...a11yProps(0)} />
        </Tabs>
           
        </Paper>
        <TabPanel value={tabValue} index={0}> */}
            <OrderList/>
        {/* </TabPanel> 
        
      </Grid>*/}
      
    </Grid>
  );
}
