//Calculate the Heating BTU needed
export default function index(sqft, efficient= false){
    var newBTU = sqft * 50;

    if(!efficient) { 
        //80% efficient 
        //Need to increase the BTU by 20%
        newBTU = newBTU *1.2
    }
    else{
        //90% efficient
        //Need to increase the BTU by 10%
        newBTU = newBTU*1.1
    }
    return Math.round(newBTU);
}