import React from "reactn";
import firebase from "firebase/app";
import {
  Grid,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Card, 
  CardContent
} from "@material-ui/core";
import HeatingEdit from "./HeatingEdit";
import CoolingEdit from "./CoolingEdit";
export default function OrderEdit(props) {
  const [status, setStatus] = React.useState(props.status);
  const handleStatusChange = (event) => {
    firebase.firestore().doc("ORDERS/"+props.id).update({status: event.target.value});
    setStatus(event.target.value);
  };
  return (
    <Grid container spacing={2}>
      <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
        <FormControl>
          <InputLabel>Order Status</InputLabel>
          <Select value={status} onChange={handleStatusChange}>
            <MenuItem value="Requested">Requested</MenuItem>
            <MenuItem value="In Progress">In Progress</MenuItem>
            <MenuItem value="Completed">Completed</MenuItem>
          </Select>
        </FormControl>
      </Grid>
      <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
          Email: <b>{props.ContactInformation.email}</b>
      </Grid>
      <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
          Phone: <b>{props.ContactInformation.phone}</b>
      </Grid>
      <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
        Address: {props.AddressData.AddressText}, {props.AddressData.zipCode}
      </Grid>

      <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
        {props.Order.sqftKnowldege ? (
          <Typography>Square Feet: <b>{props.Order.sqft}</b></Typography>
        ) : null}
      </Grid>
      {props.Order.coolingData !== "" ? (
        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
            <Card variant="outlined" style={{borderColor: 'cornflowerblue'}}>
                <CardContent>
                <Typography  component="h2" color="textSecondary" gutterBottom>
                    Cooling
                </Typography>
                    <CoolingEdit {...props.Order.coolingData}/>
                </CardContent>
            </Card>
        </Grid>
      ) : null}
      {props.Order.heatingData !== "" ? (
        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
          <Card variant="outlined" style={{borderColor: 'orangered'}}>
                <CardContent>
                    <Typography  component="h2" color="textSecondary" gutterBottom>
                        Heating
                    </Typography>
                    <HeatingEdit {...props.Order.heatingData}/>
                </CardContent>
            </Card>        
        </Grid>
      ) : null}
      
      <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
        <Grid container>
          <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
            Subtotal: {props.Order.subTotal}
          </Grid>
          <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
            Tax: {props.Order.taxTotal}
          </Grid>
          <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
            Total: {props.Order.total}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}
