import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import 'fontsource-roboto';
import { LocalizeProvider } from "react-localize-redux";
import firebase from "firebase/app";
import 'firebase/auth';
import 'firebase/storage';
import 'firebase/analytics';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
let firebaseConfig;
if (window.location.hostname !== "hvacnova.com") {
  firebaseConfig = {
    apiKey: "AIzaSyAqP9kljoTnV4cslM5c9xaXmP2QR9MHhmc",
    authDomain: "nova-dev-6ffa6.firebaseapp.com",
    projectId: "nova-dev-6ffa6",
    storageBucket: "nova-dev-6ffa6.appspot.com",
    messagingSenderId: "961542937730",
    appId: "1:961542937730:web:f8036b1b704ed756af689b",
    measurementId: "G-XYC4Z8W161"
  };
}
else{
  firebaseConfig = {
    apiKey: "AIzaSyDD-JHHVcsHYqUBIxzyem0YP8BZaRXYdNU",
    authDomain: "hvac-nova.firebaseapp.com",
    projectId: "hvac-nova",
    storageBucket: "hvac-nova.appspot.com",
    messagingSenderId: "63163206147",
    appId: "1:63163206147:web:4f4ebb45094ab759e65c36",
    measurementId: "G-83004HXEXX"
  };
}
firebase.initializeApp(firebaseConfig);
ReactDOM.render(
  <React.StrictMode>
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <LocalizeProvider>
        <App/>
      </LocalizeProvider>
    </MuiPickersUtilsProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
