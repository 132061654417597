import { Typography,Grid, Input, InputAdornment, IconButton, Popover,Card, CardContent, TextField, Button } from '@material-ui/core';
import React from 'react';
import { Translate } from 'react-localize-redux';
import {useLocation} from 'react-router-dom';
import HelpIcon from '@material-ui/icons/Help';
import NumberFormat from 'react-number-format';
import Cooling from './Cooling';
import Heating from './Heating';
import firebase from 'firebase/app';


export default function Step1(props){
    let type = new URLSearchParams(useLocation().search).get("type");
    const [sqft, setSqft] = React.useState("1000");
    const [anchorSqftHelp, setAnchorSqftHelp] = React.useState(null);
    const [SqftHelpOpen, setSqftHelpOpen] = React.useState(false);
    const [coolingPrice, setCoolingPrice] = React.useState(0);
    const [heatingPrice, setHeatingPrice] = React.useState(0);
    const [subTotal, setSubtotal] = React.useState(0);
    const [total, setTotal] = React.useState(0);
    const [tax] = React.useState(0);
    const [taxRate] =React.useState(.106);
    const [coolingData, setCoolingData] = React.useState();
    const [heatingData, setHeatingData] = React.useState();
    const [sessionData, setSessionData] = React.useState(null);
    const [checkedSession, setCheckedSession] = React.useState(false);
    const [promoCodeDiscount, setPromoCodeDiscount] = React.useState(null);
    const [promoCode, setPromoCode] = React.useState("");
    const [promoCodeError, setPromoCodeError] = React.useState(false);
    const [discountAmount, setDiscountAmount] = React.useState(0);
    const [sqftKnowledge, setSqftKnowledge] = React.useState(null);

    const handleSqftHelpOpen = (event) => {
        setSqftHelpOpen(true);
        setAnchorSqftHelp(event.currentTarget);
    };
    const handleSqftHelpClose = () => {
        setSqftHelpOpen(false);
        setAnchorSqftHelp(null);
    };
    const saveData = React.useCallback(async () => {
        var dataToSave = sessionData;
        if(dataToSave === null){
            dataToSave= {};
        }
        // var time = new Date.now
        dataToSave["Order"] =
            {
                "sqft": sqft,
                "subTotal": subTotal,
                "tax": taxRate,
                "taxTotal": tax,
                "promoCode": promoCode,
                "promoDiscount": promoCodeDiscount,
                "promoAmount": discountAmount,
                "total": total,
                "coolingData": coolingData === undefined? "": coolingData,
                "heatingData": heatingData === undefined? "": heatingData,
                "sqftKnowldege": sqftKnowledge,
                "timestamp":  Date.now()
            }
        if(checkedSession) {
            sessionStorage.setItem('session',JSON.stringify(dataToSave)); 
            var validationState = false;
            if(sqftKnowledge !== null){
                if(sqftKnowledge){
                    validationState=sqft !== "" && sqft >0;
                } 
                else if(!sqftKnowledge){
                    validationState=true;
                }
                
            }
            props.validCallback(validationState, props.step);
        }
    },[checkedSession, coolingData, heatingData, props, sessionData, sqft, subTotal, tax, taxRate, total,discountAmount, promoCode,promoCodeDiscount,sqftKnowledge])
    React.useEffect(() => {
        var subT = coolingPrice+heatingPrice;
        setSubtotal(subT);
        var discountAmount = subT*promoCodeDiscount;
        subT = subT-discountAmount;
        // var taxAmmount = subT*taxRate;
        var totalPrice = subT;//+taxAmmount;
        setDiscountAmount(discountAmount)
        // setTax(taxAmmount);
        setTotal(totalPrice);
        saveData();
    }, [coolingPrice, heatingPrice, taxRate,saveData,promoCodeDiscount])


    React.useEffect(() => {
        var session =  JSON.parse(sessionStorage.getItem('session'));
        if(session !==null){
            setSqftKnowledge(session.Order.sqftKnowldege);
            setSqft(session.Order.sqft);
            setPromoCode(session.Order.promoCode);
            setSessionData(session);
        }
        setCheckedSession(true);
        document.body.scrollTop = document.documentElement.scrollTop = 0;
      }, [])
      React.useEffect(()=>{
          if(promoCode === ""){
                setPromoCodeDiscount(null);
                setPromoCodeError(false);
                return;
            }
          var result =firebase.firestore().collection("PromoCodes").doc(promoCode);
          result.get().then((doc)=>{
            if(doc.exists){
                var PromoData = doc.data();
                if(PromoData.Enabled){
                    setPromoCodeDiscount(PromoData.Discount);
                    setPromoCodeError(false);
                    return;
                }
                
            }
            setPromoCodeError(true);
            setPromoCodeDiscount(null);
          })
      }, [promoCode])
      return (
        <Grid container spacing={2} alignItems="center" justify="center" style={{padding:"2%"}} >
            {sqftKnowledge === null? <>
                <Grid item lg={12} md={12} sm={12} xs={12}>
                    <Typography align="center">
                        <Translate id="Order.SqftKnowledgeCheckMsg"/>
                    </Typography>
                    <Typography align="center">
                        <Translate id="Order.SqftKnowledgeHelper"/>
                    </Typography>
                    
                    
                </Grid>
                <Grid item lg={2} md={2} sm={6} xs={6}>
                    <Button variant="contained" color="primary" onClick={() => setSqftKnowledge(true)}>
                        <Translate id="General.YesButtonLbl"/>
                    </Button>
                </Grid>
                <Grid item lg={2} md={2} sm={6} xs={6}>
                    <Button variant="contained" color="primary" onClick={() => setSqftKnowledge(false)}>
                        <Translate id="General.NoButtonLbl"/>
                    </Button>
                    </Grid>
                </>
            : <>
                {sqftKnowledge?<>
                    <Grid item lg={12} md={12 }>
                    <Typography  align="center">
                        <Translate id="Order.SubTitle"/>
                    </Typography>
                </Grid>
                    <Grid item lg={12} md={12} sm={12} xs={12}>
                        <Typography align="center">
                            <Translate id="Order.DescriptionSFT"/>
                        </Typography>
                    </Grid>
                    <Grid item lg={12} md={12} sm={12} xs={12} align="center">
                        <Input
                            value={sqft}
                            type="number"
                            onChange={(e) => setSqft(e.target.value)}
                            endAdornment={<InputAdornment position="end">{props.translate("Order.LabelSqft")}</InputAdornment>}
                            aria-describedby="standard-weight-helper-text"
                            name="sqft"
                            ref={props.register}
                            inputProps={{
                            'aria-label': 'sqft',
                            }}
                        />
                        <IconButton
                            onClick={handleSqftHelpOpen}
                            color="inherit">
                            <HelpIcon style={{marginLeft: "1%"}}/>
                        </IconButton>
                        <Popover
                            open={SqftHelpOpen}
                            anchorEl={anchorSqftHelp}
                            onClose={handleSqftHelpClose}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'center',
                            }}
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'center',
                            }}
                        >
                            <Typography style={{padding:"1%"}}>
                                    <Translate id="Order.SQFTHelp"/>
                                </Typography>
                        </Popover>
                        <Grid item lg={12} md={12} sm={12} xs={12}>
                        <Button variant="contained" color="primary" onClick={() => setSqftKnowledge(false)}>
                            <Typography >
                                    <Translate id="Order.IDK"/>
                            </Typography>
                        </Button>
                    </Grid>
                    </Grid>
                    {type==="1"?
                        //Cooling stuff
                        <Grid item lg={12} md={12} sm={12} xs={12}>
                            <Card variant="outlined" style={{borderColor: 'cornflowerblue'}}>
                                <CardContent>
                                <Typography  component="h2" color="textSecondary" gutterBottom>
                                    <Translate id="Order.CoolingTitle"/>
                                </Typography>
                                    <Cooling {...props} session={sessionData} type={type} sqft={sqft} updatePrice={setCoolingPrice} saveData={setCoolingData}/>
                                </CardContent>
                            </Card>
                        </Grid>
                        : null
                    }
                    {type==="2"?
                        //Heating Stuff
                        <Grid item lg={12} md={12} sm={12} xs={12}>
                            <Card variant="outlined" style={{borderColor: 'orangered'}}>
                                <CardContent>
                                    <Typography  component="h2" color="textSecondary" gutterBottom>
                                        <Translate id="Order.HeatingTitle"/>
                                    </Typography>
                                    <Heating {...props} session={sessionData} sqft={sqft} type={type} updatePrice={setHeatingPrice} saveData={setHeatingData}/>
                                </CardContent>
                            </Card>
                            
                        </Grid>
                        : null
                    }
                    {type==="0"?
                        //Full installation
                        <Grid item lg={12} md={12} sm={12} xs={12}>
                        <Card variant="outlined">
                            <CardContent>
                                <Typography  component="h2" color="textSecondary" gutterBottom>
                                    Full Installation
                                </Typography>
                                <Cooling {...props} session={sessionData} type={type} sqft={sqft} updatePrice={setCoolingPrice} saveData={setCoolingData}/>
                                <Heating {...props} session={sessionData} sqft={sqft} type={type} updatePrice={setHeatingPrice} saveData={setHeatingData}/>
                            </CardContent>
                        </Card>
                    </Grid>
                    :null}
                    
                    <Grid item lg={12} md={12} sm={12} xs={12}>
                        {/* Promo Code Card */}
                        <Card variant="outlined" >
                            <CardContent>
                                <Typography  component="h2" color="textSecondary" gutterBottom>
                                    <Translate id="Order.PromoCodeLbl"/>
                                </Typography>
                                <br/>
                                <TextField variant="outlined" error={promoCodeError}  label={props.translate("Order.EnterPromoLBL")} value={promoCode} onChange={(e) => {setPromoCode(e.target.value)}}/>
                                {promoCodeDiscount !== null? <>
                                    <Typography  gutterBottom>
                                        <br/>
                                        <Translate id="Order.PromoValidLbl"/>
                                        <NumberFormat value={promoCodeDiscount*100} displayType={'text'} decimalScale={2} thousandSeparator={true} suffix={'% ' +props.translate("Order.DiscountLbl")}/>
                                    </Typography>
                                    </>: null}
                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid item lg={12} md={12} sm={12} xs={12}>
                        {/* Total Information */}
                        <Card variant="outlined">
                            <CardContent>
                                <Grid container spacing={2}>
                                    <Grid item lg={8} xl={8} md={8} xs={7}>
                                        <Typography  >
                                            <Translate id="Order.SubTotalLbl"/>
                                        </Typography>
                                    </Grid>
                                    <Grid item lg={4} xl={4} md={4} xs={4}>
                                        <Typography  >
                                            <NumberFormat value={subTotal} displayType={'text'} decimalScale={2} thousandSeparator={true} prefix={'$'}/>
                                        </Typography>
                                    </Grid>
                                    {promoCodeDiscount != null? <>
                                        <Grid item lg={8} xl={8} md={8} xs={7}>
                                            <Typography  >
                                                <Translate id="Order.PromoCodeDiscountLbl"/> (<NumberFormat value={promoCodeDiscount*100} displayType={'text'} decimalScale={2} thousandSeparator={true} suffix={'%'}/>):
                                            </Typography>
                                        </Grid>
                                        <Grid item lg={4} xl={4} md={4} xs={4}>
                                            <Typography  >
                                                <NumberFormat value={discountAmount} displayType={'text'} decimalScale={2} thousandSeparator={true} prefix={'-$'}/>
                                            </Typography>
                                        </Grid>
                                    </>: null}
                                    {/* <Grid item lg={8} xl={8} md={8} xs={7}>
                                        <Typography  >
                                            <Translate id="Order.Taxlbl"/> ({taxRate*100}%):
                                        </Typography>
                                    </Grid>
                                    <Grid item lg={4} xl={4} md={4} xs={4}>
                                        <Typography  >
                                            <NumberFormat value={tax} displayType={'text'} decimalScale={2} thousandSeparator={true} prefix={'$'}/>
                                        </Typography>
                                    </Grid> */}
                                    <Grid item lg={8} xl={8} md={8} xs={7}>
                                    <Typography >
                                        <Translate id="Order.Totallbl"/>
                                    </Typography>
                                    </Grid>
                                    <Grid item lg={4} xl={4} md={4} xs={4}>
                                        <Typography >
                                        <b><NumberFormat value={total} displayType={'text'} wrap={true} decimalScale={2} thousandSeparator={true} prefix={'$'}/></b>
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </CardContent>
                        </Card>
                    </Grid>
                </>
               :<>
               <Grid item lg={12} md={12} sm={12} xs={12}>
                    <Typography align="center">
                        <Translate id="Order.ContinueToNextStep"/>
                    </Typography>
                </Grid>
                <Grid item lg={12} md={12} sm={12} xs={12}>
                    <Button variant="contained" onClick={() => setSqftKnowledge(true)}>
                        <Translate id="Order.EnterSQFTBtnLbl"/>
                    </Button>
                    </Grid>
                </>
               }
            
            </> 
            }
        </Grid>
      )
}