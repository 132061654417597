import {
    Grid,
    Typography,
    Switch,
  } from "@material-ui/core";
  import React from "react";

  
  export default function CoolingEdit(props) {
    return (
      <Grid container spacing={2}>
         <Grid item lg={12} md={12} sm={12} xs={12}>
          <Typography>
            Tons: <b>{props.ton}</b>
          </Typography>
        </Grid>
  
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <Typography>
              SEER: <b>{props.SEER}</b>
            </Typography>
          </Grid>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <Typography>
                Heat Pump
            </Typography>
            No
            <Switch
              disabled={true}
              color="primary"
              name="checkedB"
              inputProps={{ "aria-label": "primary checkbox" }}
            />
            Yes

          </Grid>

      </Grid>
    );
  }
  