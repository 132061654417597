import React from 'react';
import Header from './Header';
import 'firebase/firestore';
import Services from '../Services/Services';


export default function Home(props){
    return (<>
        <Header {...props}/>        
        <Services/>
      </>
    )
}