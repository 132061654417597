import * as React from 'react';
import {  Grid, Typography, } from '@material-ui/core';
import {Translate} from 'react-localize-redux';
import {ToggleButtonGroup,ToggleButton} from '@material-ui/lab';

export default function Step2(props) {
    const [days, setDays] = React.useState(() => []);
    const [checkedSession, setCheckedSession] = React.useState(false);
    const handleNewDay = (event, newFormats) => {
        setDays(newFormats);
      };
    const saveData = React.useCallback(() =>{
        var session = sessionStorage.getItem('session');
        var sessionData = JSON.parse(session);
        if(sessionData===null){return;}
        sessionData["Avaliablity"] = days;
        sessionStorage.setItem('session',JSON.stringify(sessionData));
        props.validCallback(days?.length > 0, props.step);
    },[days, props])
    React.useEffect(() =>{
        if(checkedSession){
            saveData();
        }
        
    }, [days,checkedSession,saveData])

    React.useEffect(() => {
        var session = sessionStorage.getItem('session');
        if(session !==null){
            var sessionDataJson = JSON.parse(session);
            setDays(sessionDataJson.Avaliablity);
            setCheckedSession(true);
        }
      }, [])
    return (
        <Grid container alignItems="center" justify="center" spacing={3} style={{padding:"2%"}}>
            <Grid item lg={12} md={12} sm={12} xs={12} align="center">
                <Typography variant="h6">   
                    <Translate id="Order.Schedule.Title"/>
                </Typography>
                <Typography>
                    <Translate id="Order.Schedule.Description"/>
                </Typography>
            </Grid>
            <Grid item>
                <ToggleButtonGroup value={days} onChange={handleNewDay} size="small">
                    <ToggleButton value="1">
                        <Translate id="Order.Schedule.Day1"/>
                    </ToggleButton>
                    <ToggleButton value="2">
                        <Translate id="Order.Schedule.Day2"/>
                    </ToggleButton>
                    <ToggleButton value="3">
                        <Translate id="Order.Schedule.Day3"/>
                    </ToggleButton>
                    <ToggleButton value="4">
                        <Translate id="Order.Schedule.Day4"/>
                    </ToggleButton>
                    <ToggleButton value="5">
                        <Translate id="Order.Schedule.Day5"/>
                    </ToggleButton>
                </ToggleButtonGroup>
            </Grid >
            <Grid item lg={12} md={12} sm={12} xs={12} align="center">
                <Translate id="Order.Schedule.BusinessHourMsg"/>
            </Grid>
            <Grid item lg={12} md={12} sm={12} xs={12} align="center">
                <Typography><Translate id="Order.Schedule.InformationTxt"/></Typography>
            </Grid>
        </Grid>
    );
}
