import React from 'react';
import {
    Container,
    Grid,
    CardHeader,
    CardContent,
    CardActions,
    Card,
    Button,
    Typography,
    makeStyles
} from '@material-ui/core';
import { Translate } from 'react-localize-redux';
import NumberFormat from 'react-number-format';
import { useHistory } from "react-router-dom";
import {Skeleton} from '@material-ui/lab'
const useStyles = makeStyles((theme) => ({
    '@global': {
      ul: {
        margin: 0,
        padding: 0,
        listStyle: 'none',
      },
    },
    appBar: {
      borderBottom: `1px solid ${theme.palette.divider}`,
    },
    toolbar: {
      flexWrap: 'wrap',
    },
    toolbarTitle: {
      flexGrow: 1,
    },
    link: {
      margin: theme.spacing(1, 1.5),
    },
    heroContent: {
      padding: theme.spacing(8, 0, 6),
    },
    cardHeader: {
      backgroundColor:
        theme.palette.type === 'light' ? theme.palette.grey[200] : theme.palette.grey[700],
    },
    cardPricing: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      marginBottom: theme.spacing(2),
      textAlign: 'center'
    },
    footer: {
      borderTop: `1px solid ${theme.palette.divider}`,
      marginTop: theme.spacing(8),
      paddingTop: theme.spacing(3),
      paddingBottom: theme.spacing(3),
      [theme.breakpoints.up('sm')]: {
        paddingTop: theme.spacing(6),
        paddingBottom: theme.spacing(6),
      },
    },
  }));
export default function Pricing(props){
    const services = props?.services;
    const classes = useStyles();
    let history = useHistory();
    const order = (id) =>{
      history.push("/NewEstimate?type="+id)
    }
    return(<>
        <Container maxWidth="sm" component="main" className={classes.heroContent}>
        <Typography component="h1" variant="h2" align="center" color="textPrimary" gutterBottom>
          {/* Title. Remove the translate in future.*/}
          {props?.title? props.title: <Translate id="Home.Pricing.Title"/>}
          
        </Typography>
        <Typography variant="h5" align="center" color="textSecondary" component="p">
          {/* Description. Remove the translate in future.*/}
          {props?.description? props.description: <Translate id="Home.Pricing.Description"/>}
        </Typography>
      </Container>
      {/* End hero unit */}
      <Container maxWidth="md" component="main">
        <Grid container spacing={4} alignItems="center" justify="center">
          
          {services.length === 0? 
            <Grid item lg={11} md={11} sm={11} xs={11}>
                <Card variant="outlined">
                  <Skeleton width={window.innerWidth}>
                  <CardHeader
                  
                    // subheader={tier.subheader}
                    titleTypographyProps={{ align: 'center' }}
                    subheaderTypographyProps={{ align: 'center' }}
                    className={classes.cardHeader}
                  />
                </Skeleton>
                <CardContent>
                  <div className={classes.cardPricing}>
                    <Skeleton width={window.innerWidth}/>
                    </div> 
                 <ul>
                   <Skeleton></Skeleton>
                  </ul> 
                </CardContent>
              </Card>
              
            </Grid>
            :
            services?.map((tier) => (
            // Enterprise card is full width at sm breakpoint
            <Grid item key={tier.title} lg={4} md={5} sm={10} xs={10}>
              <Card variant="outlined">
                <CardHeader
                  title={tier.title}
                  subheader={tier.subheader}
                  titleTypographyProps={{ align: 'center' }}
                  subheaderTypographyProps={{ align: 'center' }}
                  //action={tier.title === 'Pro' ? <StarIcon /> : null}
                  className={classes.cardHeader}
                />
                <CardContent>
                  <div className={classes.cardPricing}>
                    <Typography component="h5" variant="h4" color="textPrimary">
                      <Translate id="Home.Pricing.StartingLbl"/>
                      <NumberFormat value={tier.minPrice} displayType={'text'} decimalScale={0} thousandSeparator={true} prefix={'$'}/>
                    </Typography>
                  </div>
                  <Typography variant="h5" className={classes.cardPricing}>Includes</Typography>
                  <ul>
                    {tier.description.map((line) => (
                      <Typography component="li" variant="subtitle1" align="center" key={line}>
                        -{line}
                      </Typography>
                    ))}
                  </ul>
                </CardContent>
                <CardActions>
                  <Button fullWidth variant={tier.buttonVariant} onClick={() => order(tier.id)} color="primary">
                    {tier.buttonText}
                  </Button>
                </CardActions>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Container>
    </>)
}