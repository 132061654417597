import React from "reactn";
import firebase from "firebase/app";
import {
  Button,
  Grid,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from "@material-ui/core";
import MUIDataTable from "mui-datatables";
import OrderEdit from "../OrderEdit";
import { useCollection } from "react-firebase-hooks/firestore";

function formatDate(date) {
  var d = new Date(date);
  return " " + d.toDateString();
}

export default function OrderList() {
  const [orders, setOrders] = React.useState([]);
  const [ordersObj, setOrdersObj] = React.useState({});
  const [viewOrder, setViewOrder] = React.useState(false);
  const [selectedOrder, setSelectedOrder] = React.useState(null);
  const columns = ["Date","Type", "Name", "Status"];
  const [data, dataLoading] = useCollection(
    firebase.firestore().collection("ORDERS"),
    {
      snapshotListenOptions: { includeMetadataChanges: true },
    }
  );
  //Get order list
  React.useEffect(() => {
    if (!dataLoading) {
      var ordersList = [];
      var orderObjHolder = {};
      data.docs.forEach((doc) => {
        var tempData = doc.data();
        tempData.id = doc.id;
        if (tempData.status === undefined) {
          tempData.status = "Requested";
        }
      var type;
      if (tempData.coolingData !== "" && tempData.heatingData !== "") {
        type = "A/C & Furnace";
      }
      else if (tempData.coolingData !== "") {
          type = "A/C";
      }
      else if (tempData.heatingData !== "") {
          type = "Furnace";
      }

        var newItem = [
          formatDate(tempData.Order.timestamp),
          type,
          tempData.ContactInformation.name,
          tempData.status,
          doc.id,
        ];
        orderObjHolder[doc.id] = tempData;
        ordersList.push(newItem);
      });
      //Save to state
      setOrdersObj(orderObjHolder);
      setOrders(ordersList);
    }
  }, [dataLoading, data]);
  const options = {
    filterType: "dropdown",
    responsive: "standard",
    tableBodyHeight: "100%",
    selectableRowsHideCheckboxes: true,
    selectableRows: "none",
    onRowClick: (event, rowData) => {
      //On row click do stuff
      //Find the object by id and then save it to the state.
      var orderId =
        orders[rowData.rowIndex][orders[rowData.rowIndex].length - 1];
      setSelectedOrder(ordersObj[orderId]);
      setViewOrder(!viewOrder);
    },
    sortOrder: { name: "Date", direction: "desc" },
  };

  return (
    <Grid container spacing={5}>
      <Grid item lg={12} md={12} sm={12} xs={12}>
        <MUIDataTable
          title={"Current Orders"}
          data={orders}
          columns={columns}
          options={options}
        />
      </Grid>
      <Dialog open={viewOrder} onClose={() => setViewOrder(!viewOrder)}>
        <DialogTitle>
          Order for{" "}
          {selectedOrder !== null
            ? selectedOrder.ContactInformation.name
            : null}
        </DialogTitle>
        <DialogContent>
          <DialogContentText style={{ padding: 2 }}>
            <OrderEdit {...selectedOrder}/>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={()=>setViewOrder(!viewOrder)}>Close</Button>
        </DialogActions>
      </Dialog>
    </Grid>
  );
}
