import {
  Grid,
  Typography,
  Popover,
  IconButton,
  Switch,
} from "@material-ui/core";
import React from "react";
import { Translate } from "react-localize-redux";
import NumberFormat from "react-number-format";
import firebase from "firebase";
import ToggleButton from "@material-ui/lab/ToggleButton";
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup";
import HelpIcon from "@material-ui/icons/Help";
import CalculateTon from "../Calculations/CalculateTon";

export default function Cooling(props) {
  const [ton, setTon] = React.useState();
  const [calcPrice, setCalcPrice] = React.useState();
  const [SEERTabState, setSEERTabState] = React.useState("Low");
  const [anchorSEERHelp, setAnchorSEERHelp] = React.useState(null);
  const [SEERHelpOpen, setSEERHelpOpen] = React.useState(false);
  const [anchorheatPumpHelp, setanchortHeatPumpHelp] = React.useState(null);
  const [heatPumpHelpOpen, setHeatPumpHelpOpen] = React.useState(false);
  const [SEERSArr, setSEERSArr] = React.useState([]);
  const [SEERSPrices, setSEERsPrices] = React.useState();
  const [loading, setLoading] = React.useState(true);
  const [heatPumpPrice, setHeatPumpPrice] = React.useState();
  const [heatPumpCheck, setHeatPumpCheck] = React.useState(false);
  React.useEffect(() => {
    firebase
      .firestore()
      .collection("SEERs")
      .doc("priceIncrease")
      .get()
      .then((doc) => {
        if (doc.exists) {
          var data = doc.data();

          setSEERsPrices(data);

          setSEERSArr(["Low", "Medium", "High"]);

          setLoading(false);
        }
      });
    firebase
      .firestore()
      .collection("ACAccessories")
      .doc("HeatPump")
      .get()
      .then((doc) => {
        if (doc.exists) {
          var data = doc.data();
          setHeatPumpPrice(data.priceIncrease);
        }
      });
    if (props.session !== null) {
      var coolingData = props.session.Order.coolingData;
      setHeatPumpCheck(coolingData.HeatPump);
      if (coolingData.SEER === undefined) {
        setSEERTabState("Low");
      } else {
        setSEERTabState(coolingData.SEER);
      }
    } else {
      setSEERTabState("Low");
    }
  }, [props.session]);

  React.useEffect(() => {
    //>5 tons don't try and get data we will have to see a different solution
    if (ton !== ">5" && ton !== "") {
      //Full install has some speical pricing...
      if (props.type === "0") {
        firebase
          .firestore()
          .collection("Complete")
          .doc("AC")
          .get()
          .then((doc) => {
            if (doc.exists) {
              setCalcPrice(doc.data()[ton]);
            }
          });
      } else {
        //Normal Pricing
        firebase
          .firestore()
          .collection("AC")
          .doc(ton)
          .get()
          .then((doc) => {
            if (doc.exists) {
              setCalcPrice(doc.data().price);
            }
          });
      }
    }
  }, [ton, props.type]);
  React.useEffect(() => {
    props.updatePrice(calcPrice);
  }, [calcPrice, props]);
  const propsSave = props.saveData;
  React.useEffect(() => {
    var coolingData = {
      SEER: SEERTabState,
      HeatPump: heatPumpCheck,
      ton: ton,
      calcPrice: calcPrice,
      SEERsPrice: SEERSPrices,
      HeatPumpPriceIncrease: heatPumpPrice,
    };
    propsSave(coolingData);
  }, [
    calcPrice,
    SEERTabState,
    ton,
    heatPumpPrice,
    SEERSPrices,
    heatPumpCheck,
    propsSave,
  ]);
  React.useEffect(() => {
    setTon(CalculateTon(props.sqft));
  }, [props.sqft]);
  const updateHeatPump = (e) => {
    if (heatPumpCheck) {
      setCalcPrice(calcPrice - heatPumpPrice);
    } else {
      setCalcPrice(calcPrice + heatPumpPrice);
    }
    setHeatPumpCheck(e.target.checked);
  };
  const updateSEERs = (e, value) => {
    if (value === null) {
      return;
    }
    var currentSEERsIncrease = SEERSPrices[SEERTabState];
    var requestedSEERs = SEERSPrices[value];

    var newPrice = calcPrice - currentSEERsIncrease + requestedSEERs;
    setCalcPrice(newPrice);
    setSEERTabState(value);
  };
  const handleSEERHelpOpen = (event) => {
    setSEERHelpOpen(true);
    setAnchorSEERHelp(event.currentTarget);
  };

  const handleSEERHelpClose = () => {
    setSEERHelpOpen(false);
    setAnchorSEERHelp(null);
  };
  const handleHeatPumpHelpOpen = (event) => {
    setHeatPumpHelpOpen(true);
    setanchortHeatPumpHelp(event.currentTarget);
  };

  const handleHeatPumpHelpClose = () => {
    setHeatPumpHelpOpen(false);
    setanchortHeatPumpHelp(null);
  };
  return (
    <Grid container spacing={2}>
      <Grid item lg={12} md={12} sm={12} xs={12}>
        <Typography variant="h6" component="h3">
          <Translate id="Order.Cooling.RecommendedMessage" /> <b>{ton}</b>
        </Typography>
      </Grid>

      {!loading ? (
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <Typography variant="h6" component="h3">
            <Translate id="Order.Cooling.SEERsTitle" />:
          </Typography>
          <ToggleButtonGroup
            size="small"
            value={SEERTabState}
            exclusive
            onChange={updateSEERs}
          >
            {SEERSArr.map((item) => (
              <ToggleButton value={item} key={item} aria-label="left aligned">
                {props.translate("Order." + item)} <br /> +${SEERSPrices[item]}
              </ToggleButton>
            ))}
          </ToggleButtonGroup>
          <IconButton onClick={handleSEERHelpOpen} color="inherit">
            <HelpIcon style={{ marginLeft: "1%" }} />
          </IconButton>
          <Popover
            disableRestoreFocus
            open={SEERHelpOpen}
            anchorEl={anchorSEERHelp}
            onClose={handleSEERHelpClose}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "center",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
          >
            <Typography style={{ padding: "1%" }}>
              <Translate id="Order.Cooling.SEERsHelpTxt" />
            </Typography>
          </Popover>
        </Grid>
      ) : null}
      {heatPumpPrice > "0" ? (
        <Grid item>
          <Typography variant="h6" component="h3">
            {" "}
            <Translate id="Order.Cooling.HeatPumpLbl" /> +${heatPumpPrice}:
          </Typography>
          <Switch
            checked={heatPumpCheck}
            onChange={updateHeatPump}
            color="primary"
            name="checkedB"
            inputProps={{ "aria-label": "primary checkbox" }}
          />
          <IconButton onClick={handleHeatPumpHelpOpen} color="inherit">
            <HelpIcon style={{ marginLeft: "1%" }} />
          </IconButton>
          <Popover
            disableRestoreFocus
            open={heatPumpHelpOpen}
            anchorEl={anchorheatPumpHelp}
            onClose={handleHeatPumpHelpClose}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "center",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
          >
            <Typography style={{ padding: "1%" }}>
              <Translate id="Order.Cooling.HeatPumpHelptxt" />
            </Typography>
          </Popover>
        </Grid>
      ) : null}
      {props.type !== "0" ? (
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <Typography variant="h6" component="h3">
            <Translate id="Order.Cooling.PriceLable" />
            <b>
              {ton === ">5" ? (
                <Translate id="Order.Cooling.ReviewTxt" />
              ) : (
                <NumberFormat
                  value={calcPrice}
                  displayType={"text"}
                  decimalScale={2}
                  thousandSeparator={true}
                  prefix={"$"}
                />
              )}
            </b>
          </Typography>
        </Grid>
      ) : null}
    </Grid>
  );
}
