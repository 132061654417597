import {
    lightBlue,
    red
  } from "@material-ui/core/colors";

import {createMuiTheme} from "@material-ui/core/styles";
import * as locales from '@material-ui/core/locale';
export default function Theme(props){
    const palletType = props.darkState ? "dark" : "light";
    const mainPrimaryColor = props.darkState ? "#1c1e21" : lightBlue[800];
    const mainSecondaryColor = props.darkState ? red[500] : red[500];
    return createMuiTheme({
        palette: {
            type: palletType,
            primary: {
                main: mainPrimaryColor
            },
            mainSecondaryColor: {
                main: mainSecondaryColor
            },
           
        },
        typography:{
            button: {
                color: lightBlue[800]
              },
        }
    },locales[props.locale]);
}