
import CalculateBTU from '../CalculateBTU';
function roundToHalf(value) {
    var converted = parseFloat(value); // Make sure we have a number
    var decimal = (converted - parseInt(converted, 10));
    decimal = Math.round(decimal * 10);
    if (decimal === 5) { return (parseInt(converted, 10)+0.5); }
    if ( (decimal < 2) || (decimal >= 6) ) {
       return Math.round(converted);
    } else {
       return (parseInt(converted, 10)+0.5);
    }
 }

export default function index(sqft){
    var BTU = CalculateBTU(sqft);
    var ton = roundToHalf(BTU/12000);
    if(ton > "3.5" && ton < "5"){
        ton = Math.round(ton).toString();
    }
    else if(ton > "5"){
        ton = ">5";
    }
    else{
        ton = ton.toString();
    }
    return ton
}